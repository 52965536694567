<template>
	<div class="termsAndConditions">
		<div v-if="serverBusy" class="loading">
			<span class="loading-icon"></span>
			<span class="loading-message">{{ busyText }}</span>
		</div>
		<div class="btn-container">
			<button class="btn" v-if="tosContent" @click="eventBus.emit('termsViewed')">{{ termsAndConditions.buttonText }}</button>
		</div>
		<div v-if="latestVersion.format.includes('html')" v-html="tosContent"></div>
		<div v-else>
			<h1>{{ languageStrings.termsAndConditions }}</h1>
			<div>{{ tosContent }}</div>
		</div>
		<img v-if="latestVersion.format.includes('image')" :src="tosContent" />
	</div>
</template>

<script>
export default {
	name: "TermsAndConditions",
	props: {
		playerState: Object,
		languageStrings: Object,
		languageErrorStrings: Object,
		tosContent: String,
		latestVersion: Object,
	},
	data() {
		return {
			termsAndConditions: require(`@/dependencies/terms-${this.countryCode}.json`),
			serverBusy: false,
			busyText: "",
			termsViewed: false,
		};
	},
	watch: {},
	created() {},
	mounted() {},
	methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.termsAndConditions {
	position: fixed;
	display: grid;
	align-items: center;
	justify-content: center;
	padding: 30px 15px;
	top: 60px;
	left: 0;
	width: 100%;
	height: 85%;
	overflow: hidden auto;
	background-color: rgb(0 0 0/75%);
	backdrop-filter: blur(8px);
	z-index: 9999;
}

.btn-container {
	margin: auto;
}

.terms-container button {
	position: fixed;
	top: 15px;
	width: 20em;
}

/* .terms-container {
	overflow: hidden auto;
} */

/* iOS specific CSS */
@supports (-webkit-touch-callout: none) {
	.termsAndConditions {
		padding-top: 30px;
	}
}

@media (min-width: 768px) {
	.termsAndConditions {
		width: 80%;
		top: 0;
		left: 20%;
	}
}
</style>
